<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Editar Venda</h4>
          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'vendas' })"
            >
              <i
                class="bx bx-list-ol font-size-16 align-middle mr-0 mr-md-2"
              ></i>
              <span class="d-none d-md-inline-block">Listar Vendas</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="sale == null">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Item não encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left" v-else>
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>Produto</th>
                    <th>Nome</th>
                    <th>Preço</th>
                    <th>Quantidade</th>
                    <th colspan="2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in sale.product"
                    :key="product.id"
                  >
                    <td>
                      <span v-for="img in product.image" :key="img.id">
                        <img
                          v-if="img.capa == 1"
                          :src="img.image300"
                          :alt="product.name"
                          class="avatar-md"
                        />
                      </span>
                    </td>
                    <td>
                      <h5 class="font-size-14 text-truncate">
                        {{ product.name }}
                      </h5>
                    </td>
                    <td>{{ product.price | currency('R$ ') }}</td>
                    <td>
                      <div style="width: 120px;">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click.prevent="amountProduct(1, product)"
                            >
                              -
                            </button>
                          </div>
                          <input
                            v-model="product.amount"
                            readonly=""
                            type="text"
                            class="form-control text-center"
                          />
                          <div class="input-group-append">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click.prevent="amountProduct(2, product)"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ (product.price * product.amount) | currency('R$ ') }}
                    </td>
                    <td>
                      <a
                        href="#"
                        class="action-icon text-danger"
                        @click.prevent="removerProduct(product, index)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h5 class="card-title mb-3">Endereço de entrega</h5>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label>CEP</label>
                <b-form-group>
                  <b-form-input
                    placeholder="CEP"
                    for="text"
                    v-model="sale.delivery_cep"
                    v-mask="'#####-###'"
                    v-on:keyup="buscar"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Estado</label>
                <multiselect
                  :options="estados"
                  v-model="estado"
                  class="helo"
                  label="text"
                  placeholder="Estado"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Cidade</label>
                <multiselect
                  :options="cidades"
                  class="helo"
                  v-model="sale.delivery_city"
                  placeholder="Cidade"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Avenida/Rua</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Avenida/Rua"
                    for="text"
                    v-model="sale.delivery_street"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Número</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Número"
                    for="text"
                    v-model="sale.delivery_number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Bairro</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Bairro"
                    for="text"
                    v-model="sale.delivery_district"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Complemento</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Complemento"
                    for="text"
                    v-model="sale.delivery_complement"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="text-sm-right mt-2 mt-sm-0">
                  <button class="btn btn-success" @click.prevent="submitSale()">
                    <i class="bx bx-save mr-1"></i>
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <!--<div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Cupom</h5>
            <b-input-group>
                <b-form-input placeholder="Código do cupom"></b-form-input>
                <b-input-group-append>
                    <b-button variant="info"><i class="bx bx-search"></i></b-button>
                </b-input-group-append>
            </b-input-group>
          </div>
        </div>-->
        <div class="card">
          <div class="card-header bg-danger text-white" v-if="errorfrete">
            Ocorreu um erro ao calcular o frete, verifique as informações e
            tente novamente.
          </div>
          <div class="card-body">
            <h5 class="card-title mb-3">Frete</h5>
            <multiselect
              :options="sale.options_frete"
              class="helo"
              label="codigo"
              placeholder="Selecione o frete"
              select-label="Selecionar"
              deselect-label="Remover"
              v-model="frete"
              :allow-empty="false"
              :custom-label="customLabel"
              @input="freteSale()"
            ></multiselect>
          </div>
          <div class="card-footer bg-white pb-3 text-right">
            <button class="btn btn-success" @click.prevent="calcularFrete()">
              Calcular frete
            </button>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Detalhes</h4>
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td>Subtotal:</td>
                    <td class="text-right">
                      {{ sale.price_subtotal | currency('R$ ') }}
                    </td>
                  </tr>
                  <!--<tr>
                    <td>Cupom:</td>
                    <td class="text-danger text-right">- R$ 157</td>
                  </tr>-->
                  <tr>
                    <td>Frete:</td>
                    <td class="text-right">
                      {{ sale.shipping_price | currency('R$ ') }}
                    </td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <th class="text-right">
                      {{ sale.price_total | currency('R$ ') }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import axios from 'axios'
import brasil from '@/assets/brasil.json'
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      sale: null,
      loading: true,
      error: false,
      cidades: [],
      frete: null,
      estado: null,
      estados: [
        { value: null, text: 'Selecione um estado' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
      errorfrete: false,
      loadingAmount: false,
    }
  },
  mounted() {
    if (this.$route.params.id <= 0) {
      this.loading = false
      this.error = true
    }
    this.findSale()
  },
  watch: {
    estado: function () {
      if (this.estado != '') {
        this.cidades = brasil[this.estado.value].cidades
      }
    },
  },
  methods: {
    ...mapActions('sale', [
      'ActionSaleFind',
      'ActionSaleUpdate',
      'ActionSaleProductUpdate',
      'ActionFreteSaleAll',
      'ActionSaleFreteUpdate',
    ]),
    customLabel({ codigo, prazo_entrega, valor }) {
      return `${codigo} (${prazo_entrega} Dias) - R$ ${valor}`
    },
    async amountProduct(tipo, product) {
      try {
        this.loadingAmount = true
        if (tipo == 1) {
          product.amount = product.amount - 1
        } else if (tipo == 2) {
          product.amount = product.amount + 1
        }
        if (product.amount <= 0) {
          product.amount = 1
        }
        var data = {
          id: this.sale.id,
          id_user: this.sale.id_user,
          product_id: product.id,
          trash: 0,
          product_amount: product.amount,
        }
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionSaleProductUpdate(dados).then((res) => {
          this.sale = res.data.data
          this.estado = {
            value: res.data.data.delivery_state,
            text: res.data.data.delivery_state,
          }
          this.frete = {
            codigo: res.data.data.shipping_type,
            prazo_entrega: res.data.data.shipping_date,
            valor: res.data.data.shipping_price.toFixed(2),
          }
          if (res.data.data.options_frete == null) {
            this.sale.options_frete = []
          }
          this.loadingAmount = false
          this.calcularFrete()
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
        })
        this.loadingAmount = false
      }
    },
    async calcularFrete() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Calculando frete...',
          icon: 'info',
          showConfirmButton: false,
        })
        this.sale.cep = this.sale.delivery_cep
        await this.ActionFreteSaleAll(this.sale).then((res) => {
          this.estado = {
            value: res.data.data.delivery_state,
            text: res.data.data.delivery_state,
          }
          this.frete = {
            codigo: res.data.data.shipping_type,
            prazo_entrega: res.data.data.shipping_date,
            valor: res.data.data.shipping_price.toFixed(2),
          }
          if (res.data.data.options_frete == null) {
            res.data.data.options_frete = []
          }
          this.sale = res.data.data
          this.$swal({
            title: 'Sucesso',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
          })
          this.errorfrete = false
        })
      } catch (e) {
        this.sale.options_frete = []
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
        })
        this.errorfrete = true
      }
    },
    async findSale() {
      try {
        await this.ActionSaleFind(this.$route.params.id).then((res) => {
          this.sale = res.data.data
          this.estado = {
            value: res.data.data.delivery_state,
            text: res.data.data.delivery_state,
          }
          this.frete = {
            codigo: res.data.data.shipping_type,
            prazo_entrega: res.data.data.shipping_date,
            valor: res.data.data.shipping_price.toFixed(2),
          }
          if (res.data.data.options_frete == null) {
            this.sale.options_frete = []
          }
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.sale = null
        this.sale = {
          options_frete: [],
        }
      }
    },
    async submitSale() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        this.sale.delivery_state = this.estado.value

        var dados = {
          data: this.sale,
          id: this.sale.id,
        }
        await this.ActionSaleUpdate(dados).then((res) => {
          this.sale = res.data.data
          this.estado = {
            value: res.data.data.delivery_state,
            text: res.data.data.delivery_state,
          }
          this.frete = {
            codigo: res.data.data.shipping_type,
            prazo_entrega: res.data.data.shipping_date,
            valor: res.data.data.shipping_price.toFixed(2),
          }
          if (res.data.data.options_frete == null) {
            this.sale.options_frete = []
          }

          this.calcularFrete()
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async freteSale() {
      try {
        if (this.frete.codigo == '' || this.frete.codigo == null) {
          this.$swal({
            title: 'Selecione o frete',
            text: 'Selecione um frete válido',
            icon: 'warning',
            timer: 2000,
            timerProgressBar: true,
          })
        } else {
          this.$swal({
            title: 'Aguarde!',
            text: 'Salvando Dados',
            icon: 'info',
            showConfirmButton: false,
          })

          var data = {
            id: this.sale.id,
            id_user: this.sale.id_user,
            codigo: this.frete.codigo,
          }
          var dados = {
            data: data,
            id: data.id,
          }
          await this.ActionSaleFreteUpdate(dados).then((res) => {
            this.sale = res.data.data
            this.estado = {
              value: res.data.data.delivery_state,
              text: res.data.data.delivery_state,
            }
            this.frete = {
              codigo: res.data.data.shipping_type,
              prazo_entrega: res.data.data.shipping_date,
              valor: res.data.data.shipping_price.toFixed(2),
            }
            if (res.data.data.options_frete == null) {
              this.sale.options_frete = []
            }
            this.$swal({
              title: 'Sucesso',
              text: 'Ação realizada com sucesso!',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
            })
          })
        }
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    removerProduct(product, index) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(product, index)
        }
      })
    },
    async removerItemUp(product, index) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var data = {
          id: this.sale.id,
          id_user: this.sale.id_user,
          product_id: product.id,
          trash: 1,
          product_amount: product.amount,
        }
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionSaleProductUpdate(dados).then((res) => {
          this.sale = res.data.data
          this.estado = {
            value: res.data.data.delivery_state,
            text: res.data.data.delivery_state,
          }
          this.frete = {
            codigo: res.data.data.shipping_type,
            prazo_entrega: res.data.data.shipping_date,
            valor: res.data.data.shipping_price.toFixed(2),
          }
          if (res.data.data.options_frete == null) {
            this.sale.options_frete = []
          }
          this.calcularFrete()
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    buscar: function () {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.sale.delivery_cep)) {
        axios
          .get('https://viacep.com.br/ws/' + this.sale.delivery_cep + '/json/')
          .then((response) => {
            this.sale.delivery_state = response.data.uf
            this.estado = { value: response.data.uf, text: response.data.uf }
            this.sale.delivery_city = response.data.localidade
            this.sale.delivery_street = response.data.logradouro
            this.sale.delivery_district = response.data.bairro
          })
      }
    },
  },
}
</script>
